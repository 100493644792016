import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, NavItem } from "react-bootstrap"
//import ScrollAnimation from "react-animate-on-scroll"
import Image1 from "../../../images/img6.jpg"
import Slider from "react-slick"
import "./RelatedResearch.scss"
import ReactMarkdown from "react-markdown"
import GetURL from "@Components/common/site/get-url"
import { useWindowSize } from "../../../hooks/window-size"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  containerVariants,
  delayItemsFromBottom,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import PlayVideo from "../../PlayVideo/PlayVideo"
import { InView } from "react-intersection-observer"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Research_Page_Url } from "@Components/common/site/constants"
import NoImage from "../../../images/no-image.png"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import moment from "moment"
const RELATED_NEWS = gql`
  query GetResearch($Title: String!) {
    researchs(sort: "Date:desc", where: { Title_ne: $Title }) {
      id
      imagetransforms
      Title
      URL
      id
      Date
      Tail_Image {
        url
      }
    }
  }
`
const RelatedResearch = props => {
  const [isPlay, setIsPlay] = useState(false)

  const playVideoHandler = () => {
    setIsPlay(true)
  }
  const {
    loading: relatednewsloading,
    error: relatednewserror,
    data: relatednews,
  } = useQuery(RELATED_NEWS, {
    variables: { Title: props.CurrentNews },
  })
  const MotionCol = motion(Col)
  const MotionRow = motion(Row)
  const [windowWidth] = useWindowSize()
  const AdvantageList = [
    {
      Images: Image1,
      Advantage: "Why Sell with DNG",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies.",
      MoreText: "+ find out more",
    },
    {
      Images: Image1,
      Advantage: "Branch Finder",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies.",
      MoreText: "+ find out more",
    },
    {
      Images: Image1,
      Advantage: "Guide to Selling",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies.",
      MoreText: "+ find out more",
    },
    {
      Images: Image1,
      Advantage: "Customer Reviews",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies.",
      MoreText: "+ find out more",
    },
  ]
  var settings = {
    dots: false,
    nav: true,
    infinite: relatednews?.researchs?.length > 4 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          infinite: relatednews?.researchs?.length > 4 ? true : false,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          infinite: relatednews?.researchs?.length > 4 ? true : false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          infinite: relatednews?.researchs?.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
          infinite: relatednews?.researchs?.length > 1 ? true : false,
        },
      },
    ],
  }
  const ImageRender = ({ alt, image_url, node, processedImages }) => {
    return (
      <ImageTransform
        imagesources={image_url}
        renderer="srcSet"
        imagename="researchs.Tail_Image.tail"
        attr={{ alt: alt, class: "" }}
        imagetransformresult={processedImages}
        id={node.id}
      />
    )
  }
  return (
    <div className="relatedresearch-block analysis-block">
      <Container>
        <Row>
          <InView {...inViewOptions}>
            {({ ref, inView }) => (
              <MotionCol
                ref={ref}
                lg="12"
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={contentItemStagger}
              >
                <motion.h2 variants={contentItem}>
                  other Research that may interest you
                </motion.h2>
              </MotionCol>
            )}
          </InView>
        </Row>
        {/* {windowWidth < 1199 && */}
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.ul
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={contentItemStagger}
            >
              <Slider {...settings}>
                {relatednews?.researchs?.map((item, index) => {
                  let processedImages = JSON.stringify({})
                  if (item?.imagetransforms?.Tail_Image_Transforms) {
                    processedImages = item.imagetransforms.Tail_Image_Transforms
                  }
                  return (
                    <li
                      className="opinion-item"
                    >
                      <div className="advantage-item">
                        <Link
                          to={Research_Page_Url.alias + "/" + item.URL + "/"}
                        >
                          <picture>
                            {/* <img src={item.Tail_Image?.url} alt={item.Tail_Image?.alternativeText} /> */}
                            {item.Tail_Image?.url ? (
                              <ImageRender
                                alt={
                                  item.Tail_Image?.alternativeText
                                    ? item.Tail_Image?.alternativeText
                                    : item.Title + " - DNG Estate Agents"
                                }
                                processedImages={processedImages}
                                image_url={item?.Tail_Image?.url}
                                node={item}
                              />
                            ) : (
                              <img
                                src={NoImage}
                                alt={item.Tail_Image?.alternativeText}
                              />
                            )}
                          </picture>
                        </Link>
                        <div className="advantage-info">
                          <span className="date-text">
                            {moment(item?.Date).format("DD MMMM YYYY")}
                          </span>
                          <h3>
                            <Link
                              to={
                                Research_Page_Url.alias + "/" + item.URL + "/"
                              }
                            >
                              {item.Title}
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </Slider>
            </motion.ul>
          )}
        </InView>
      </Container>
    </div>
  )
}

export default RelatedResearch
